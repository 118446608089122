import React, { useState } from "react";
import "./headerStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar } from "../redux/Actions/UserAction";
import { useMediaQuery } from "react-responsive";

const Header = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const isMiniPad = useMediaQuery({
		query: "(max-width: 1280)",
	});
	const { navbar, userInfo, profilePic } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const menuBurger = require("../app/assets/images/burger.png");
	const navigate = useNavigate();
	const location = useLocation();
	const currentRouteName = location.pathname;
	const [profileModal, setProfileModal] = useState(false);
	const [language, setLanguage] = useState("en"); // State to manage selected language
	const handleLanguageChange = (language) => {
		setLanguage(language);
		console.log(`Selected language: ${language}`);
	};
	const onClickNavigate = (path) => {
		if (path === "/profile" && (isMobile || isMiniPad)) {
			if (currentRouteName !== "/profile" && profileModal) {
				navigate(path);
			}
			setProfileModal(!profileModal);
		} else {
			setProfileModal(false);
			navigate(path);
		}
	};

	return (
		<div className="header-container">
			<div className="header-wrapper">
				<div className="menu-wrapper">
					<img
						className={`header-menu-button`}
						onClick={() => dispatch(setNavbar(!navbar))}
						src={menuBurger}
						alt="Image description"
					/>
				</div>

				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ marginLeft: 12 }}>
						<select
							onChange={(e) => handleLanguageChange(e.target.value)} // Function to handle language change
							className="language-selector"
							style={{ padding: "5px", borderRadius: "4px" }}
							value={language}
						>
							<option value="en">🌐 English</option>
							<option value="hi">🌐 Hindi</option>
						</select>
					</div>

					<div className="profile-container" style={{ marginLeft: 12 }}>
						<div
							onClick={() => onClickNavigate("/profile")}
							className="circular-container"
						>
							<img
								src={profilePic}
								alt="profile"
								className="header-profile-picture"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
