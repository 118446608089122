import React, { useState } from "react";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import username from "../../assets/images/username.png";
import { Reset } from "../../API";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const Forgot = () => {
	const { locale } = useSelector((state) => state.user);
	const [loginCredential, setLoginCredential] = useState({});
	const navigate = useNavigate();
	const [inputKey, setInputKey] = useState(0);
	const dispatch = useDispatch();
	const onChangeText = (val, key_name) => {
		setLoginCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));
	};
	const handleForgot = () => {
		toast.loading("Loading...");
		var formdata = new FormData();
		formdata.append("registered_id", loginCredential.email);
		try {
			Reset(formdata).then((response) => {
				if (response?.response === "ok") {
					console.log(response);
					toast.remove();
					toast.success("Request send please check email!");
					navigate("/");
					setLoginCredential({});
					setInputKey((prevKey) => prevKey + 1);
				} else {
					toast.remove();
					toast.error("Please enter register emailId !");
				}
			});
		} catch (error) {
			console.log(error);
			toast.remove();
			toast.error("Something went wrong !");
		}
	};

	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					textAlign: "center",
					height: "100vh",
				}}
			>
				<div className="card">
					<img
						alt="img"
						src={require("../../assets/images/logo.png")}
						className="logo"
					/>
					<p className="welcome">{locale.ForgotPassword}</p>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							margin: "auto",
						}}
					>
						<Input
							icon={username}
							placeholder={locale.EnterEmail}
							onChangeText={onChangeText}
							key_name="email"
							value={loginCredential?.email}
							key={inputKey}
						/>
					</div>

					<div class="container">
						<a class="btn btn-1" onClick={handleForgot}>
							{locale.Send}
						</a>
					</div>
					<p style={{ textAlign: "center", color: "rgba(0,0,0,0.4)" }}>
						<a
							style={{ textDecoration: "none" }}
							href="https://play.google.com/store/apps/details?id=com.certify"
						>
							Download Our App
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Forgot;
