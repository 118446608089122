import React from "react";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AggreementVerify } from "../../API";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const Aggrement = () => {
	const { locale } = useSelector((state) => state.user);
	const { state } = useLocation();
	const navigate = useNavigate();
	const handleAggre = () => {
		let loader = toast.loading("Loading...");
		var formdata = new FormData();
		formdata.append("email", state?.userData?.email);
		try {
			AggreementVerify(formdata).then((response) => {
				toast.remove(loader);
				toast.success(
					"Account created successfully! please verify your email. "
				);
				navigate("/");
			});
		} catch (error) {
			console.log(error);
			toast.error(JSON.stringify(error));
		}
	};
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.png")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="containers">
					<div style={{ textAlign: "center" }}>
						<img
							alt="img"
							src={require("../../assets/images/logo.png")}
							className="logo"
						/>
						<p className="welcome">{locale.AggrementSignature}</p>
					</div>
					<span>{state?.data?.agreement}</span>
					<div class="container" style={{ marginTop: 10 }}>
						<a class="btn btn-1" style={{ borderRadius: 10 }}>
							<Link to={"/"} style={{ textDecoration: "none", color: "#fff" }}>
								Decline
							</Link>
						</a>
						<a class="btn btn-2" style={{ borderRadius: 10 }}>
							<div
								onClick={handleAggre}
								style={{ textDecoration: "none", color: "#fff" }}
							>
								{locale.Accept}
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Aggrement;
