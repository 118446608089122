export const TOKEN_FUNCTION = "TOKENFUNCTION"
export const USERINFO = "USERINFO"
export const NAV_BAR = "NAVBAR"
export const SettingType = "SettingType"
export const PictureType = "PictureType"
export const TeriffPlan = "TeriffPlan"
export const TeriffData = "TeriffData"
export const PaymentHistoryAction = "PaymentHistoryAction"
export const Language="Language"


