import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadPdf } from "../../../utility";

const CertifiDetails = () => {
	const state = useLocation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const { ATTACH, BODY, FROM, CC, SUBJECT, TO } = state?.state?.data || {};
	useEffect(() => {
		if (!state?.state) {
			navigate("/certifi-email");
		}
	}, [state, navigate]);
	const viewCertificate = () => {
		return (
			<div className="about_card">
				<div
					style={{
						border: "2px dotted #14619d",
						height: isMobile ? null : "80vh",
						margin: "20px 5%",
						borderRadius: "12px",
						textAlign: "center",
						padding: "20px",
						boxSizing: "border-box",
						overflow: "scroll",
					}}
				>
					<label
						style={{
							fontSize: isMobile ? "1rem" : "1.8rem",
							letterSpacing: "1px",
							fontWeight: "bold",
							display: "block",
							marginBottom: "10px",
						}}
					>
						Certified Email
					</label>
					<p
						style={{
							fontSize: isMobile ? "0.6rem" : "0.8rem",
							textAlign: "right",
							fontWeight: "600",
							letterSpacing: "1px",
							marginBottom: "10px",
						}}
					>
						From: {FROM}
					</p>
					<p
						style={{
							fontSize: isMobile ? "0.6rem" : "1rem",
							textAlign: "left",
							fontWeight: "500",
							letterSpacing: "1px",
							marginBottom: "10px",
						}}
					>
						<label style={{ fontWeight: "600" }}>Subject:</label> {SUBJECT}
					</p>
					<hr style={{ margin: "10px 0" }} />
					<p
						style={{
							fontSize: isMobile ? "0.6rem" : "1rem",
							textAlign: "left",
							fontWeight: "500",
							letterSpacing: "1px",
							marginBottom: "10px",
						}}
					>
						<label style={{ fontWeight: "600" }}>TO:</label> {TO}
					</p>
					<hr style={{ margin: "10px 0" }} />
					<p
						style={{
							fontSize: isMobile ? "0.6rem" : "1rem",
							textAlign: "left",
							fontWeight: "500",
							letterSpacing: "1px",
							marginBottom: "10px",
							minHeight: isMobile ? 40 : 200,
						}}
					>
						<label style={{ fontWeight: "600" }}>Body:</label>
						<br />
						<br /> {BODY}
					</p>
					<hr style={{ margin: "10px 0" }} />
					<p
						style={{
							fontSize: isMobile ? "0.6rem" : "1rem",
							textAlign: "left",
							fontWeight: "500",
							letterSpacing: "1px",
							marginBottom: "10px",
						}}
					>
						<label style={{ fontWeight: "600" }}>Attachments:</label>
						<br />
						<br />
						{ATTACH?.map((item) => {
							return (
								<label
									style={{ color: "#14619C", cursor: "pointer" }}
									onClick={() => downloadPdf(item?.data, item.title)}
								>
									{item.title}
								</label>
							);
						})}
					</p>
				</div>
			</div>
		);
	};
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div
					className="about_card"
					style={{
						alignContent: "center",
					}}
				>
					{viewCertificate()}
				</div>
			</div>
		</div>
	);
};

export default CertifiDetails;
