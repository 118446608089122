export default {
	Login: "लॉग इन करें",
	ForgotPassword: "पासवर्ड भूल गए?",
	EnterPassword: "पास वर्ड दर्ज करें",
	EnterEmail: "ईमेल दर्ज करें",
	DontHaveAccount: "क्या आपके पास खाता नहीं है?",
	Create: "खाता बनाएं",
	FirstName: "प्रथम नाम दर्ज करें",
	LastName: "अंतिम नाम दर्ज करें",
	PassDesc: `1 अपर केस कैरेक्टर, 1 लोअर केस कैरेक्टर, 1 अंक और अंडरस्कोर के अलावा 1 विशेष कैरेक्टर, इसमें कम से कम 8 कैरेक्टर और अधिकतम 32 कैरेक्टर होने चाहिए।`,
	ConfirmPassword: "पुष्टि पासवर्ड दर्ज करें",
	ContactNumber: "संपर्क नंबर दर्ज करें",
	SecretQuestion: "गोपनीय प्रश्न",
	EnterAnswer: "उत्तर दर्ज करें",
	Address: "पता",
	ByClick: "साइन अप पर क्लिक करके, आप हमारी शर्तों से सहमत होते हैं",
	term: "शर्त",
	AndRead: "और आपने हमारा लेख पढ़ लिया है",
	DataPolicy: "डेटा नीति और कुकी उपयोग",
	CreateAccount: "खाता बनाएं",
	Agreement: "समझौता पत्र",
	Accept: "स्वीकार करना",
	CertifiEmailSent: "प्रमाणिक ईमेल भेजा गया",
	EmailNotDelivered: "ईमेल डिलीवर नहीं हुआ",
	DataNotFound: "डाटा प्राप्त नहीं हुआ!",
	Subject: "विषय",
	SMSDelivery: "SMS डिलीवरी",
	EmailReadTime: "Email पढ़ने का समय",
	Home: "होम्‌",
	Payment: "पेमेंट",
	Profile: "प्रोफाइल",
	Setting: "सेटिंग",
	Email: "ईमेल",
	Settings: "सेटिंग",
	SrNo: "क्रमांक।",
	CertifiEmailCompose: "प्रमाणित ईमेल लिखें",
	EnterDescription: "विवरण दर्ज करें",
	Send: "भेजें",
	AttachFile: "दस्तावेज संलग्न करें",
	Save: "सेव",
	BasicInfo:
		"बुनियादी जानकारी, जैसे आपका नाम और ईमेल आईडी, जो आपने खाता बनाते समय दर्ज की थी",
	Cancel: "रद्द करना",
	DraftEmail: "ड्राफ्ट ईमेल",
};
