import React from "react";
import { useMediaQuery } from "react-responsive";
const Contact = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="about_card">
					<div className="about_container">
						<img
							alt="img"
							src={require("../../assets/images/contactus.webp")}
							style={{
								width: "auto",
								height: isMobile ? 200 : 300,
								display: "flex",
								margin: "auto",
							}}
						/>
						<div
							className="contact-us"
						>
							<p>Technical Query: tech@mail.machynworks.in </p>
							<p>Customer Support : vcare@mail.machynworks.in</p>{" "}
							<p>Business Development : bd@mail.machynworks.in</p>{" "}
							<p>Phone: +91-44-43848585</p>
							<div className="contact-office">
								<p>M/s Machynworks Software Solutions LLP</p>
								<p>
									Regd Office: 297, 12th Street, Viduthalai Nagar, S. Kolathur,
									Chennai 600 117
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
