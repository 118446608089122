import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import "./AuthNavbar.css";
const AuthNavbar = () => {
	const itemList = [
		{
			id: 1,
			lable: "Home",
			path: "/",
			icon: "fa fa-home icon fa-lg",
		},
		{
			id: 2,
			lable: "About",
			path: "/about",
			icon: "fa fa-address-book-o",
		},
		{
			id: 3,
			lable: "CONTACT US",
			path: "/contact",
			icon: "fa fa-phone-square",
		},
		{
			id: 4,
			lable: "Certifi E-Mail",
			path: "/certifi-email",
			icon: "fa fa-inbox",
		},
		{
			id: 5,
			lable: "Product",
			path: "/product",
			icon: "fa fa-list-alt",
		},

		{
			id: 6,
			lable: "POLICIES & CONDITIONS",
			icon: "fa fa-handshake-o",
			subItem: [
				{
					id: 1,
					lable: "Terms & condition",
					path: "https://web.certifi.biz/Terms_Condition_MWS.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 2,
					lable: "Privacy Policy",
					path: "https://web.certifi.biz/PrivacyPolicies_MWS.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 3,
					lable: "Disclaimer Policy",
					path: "https://web.certifi.biz/DisclaimerPolicies_MWS.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 4,
					lable: "Refund Policy",
					path: "https://web.certifi.biz/RefundPolicies_MWS.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 4,
					lable: "Charges",
					path: "https://web.certifi.biz/Charges.pdf",
					icon: "fa fa-hand-o-right",
				},
			],
		},
		{
			id: 7,
			lable: "PRODUCT HELP",
			icon: "fa fa-yelp",
			subItem: [
				{
					id: 1,
					lable: "Registration",
					path: "https://web.certifi.biz/HowTo_Registration.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 2,
					lable: "Mail & SMS",
					path: "https://web.certifi.biz/HowToUse_MailSMS.pdf",
					icon: "fa fa-hand-o-right",
				},
				{
					id: 3,
					lable: "FAQ",
					path: "https://web.certifi.biz/FAQ.pdf",
					icon: "fa fa-hand-o-right",
				},
			],
		},
	];
	const location = useLocation();
	const currentRouteName = location.pathname;
	const navigate = useNavigate();
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const [expandedItems, setExpandedItems] = useState([]);
	const [isAuthNavbar, setAuthNavbar] = useState(!isMobile);

	const handleItemClick = (itemId, path) => {
		navigate(path);
		setExpandedItems((prevExpandedItems) => {
			if (prevExpandedItems.includes(itemId)) {
				return prevExpandedItems.filter((id) => id !== itemId);
			} else {
				return [...prevExpandedItems, itemId];
			}
		});
		if (isMobile) {
			setAuthNavbar(false);
		}
	};

	return (
		<div className="auth-navbar-wrapper">
			<div className={`auth-navbar  ${isAuthNavbar ? "open" : "closed"}`}>
				{isMobile && (
					<p
						onClick={() => setAuthNavbar(!isAuthNavbar)}
						style={{
							textAlign: "right",
							color: "#fff",
							cursor: "pointer",
						}}
					>
						◀︎
					</p>
				)}
				{itemList.map((item, index) => (
					<>
						<div
							onClick={() => handleItemClick(item.id, item?.path)}
							className={`item-list ${
								currentRouteName === item?.path ? "active" : "in-active"
							}`}
							key={item.id}
						>
							<i className={item.icon} />
							<p>{item.lable}</p>
							{!item?.path && <i className="fa fa-sort-desc" />}
						</div>
						{expandedItems.includes(item.id) && item.subItem && (
							<div className="subitem-list">
								{item.subItem.map((subItem) => (
									<div
										key={subItem.id}
										className="subitem"
										onClick={() => window.open(subItem.path, "_blank")}
									>
										<i className={subItem.icon} />
										<p>{subItem.lable}</p>
									</div>
								))}
							</div>
						)}
					</>
				))}
			</div>
		</div>
	);
};

export default AuthNavbar;
