import React, { useState } from "react";

const Captcha = ({ captcha, refreshCaptcha, userInput, handleInputChange }) => {
	return (
		<div style={{ marginTop: "10px" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<div
					style={{
						fontSize: "20px",
						letterSpacing: "5px",
						fontWeight: "bold",
						color: "#fff",
						backgroundColor: "#000",
						borderRadius: 6,
						padding: "4px 10px",
					}}
				>
					{captcha}
				</div>
				<div
					style={{
						cursor: "pointer",
						color: "#000",
						border: "1px solid #fff",
						padding: "4px 6px",
						borderRadius: 4,
					}}
				>
					<i onClick={refreshCaptcha} class="fa fa-refresh" />
				</div>
			</div>
			<div
				style={{
					display: "flex",
					margin: "10px 0",
				}}
			>
				<input
					type="text"
					value={userInput}
					onChange={handleInputChange}
					placeholder="Enter CAPTCHA"
					style={{
						padding: "6px",
						fontSize: "16px",
						borderRadius: 4,
					}}
				/>
			</div>
		</div>
	);
};

export default Captcha;
