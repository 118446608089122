import React, { useRef, useState } from "react";
import "./Profile.css";
import username from "../../assets/images/username.png";
import phone from "../../assets/images/phone.png";
import location from "../../assets/images/location.png";
import camera from "../../assets/images/camera.png";
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import emailIcon from "../../assets/images/white-email.png";
import { useDispatch, useSelector } from "react-redux";
import {
	changeaddr,
	delete_ref,
	isemailavailable,
	setmymobilenum,
	updatereferralcode,
	uploadmypic,
	whitelistmail,
} from "../../API";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Loader";
import { EmailRegexPattern, NumberRegexPattern } from "../../../utility";
import { setProfilePicture } from "../../../redux/Actions/UserAction";
import usePopupModal from "../../../components/popUpModal";

export const Profile = () => {
	const fileInputRef = useRef(null);
	const fileInput = useRef(null);
	const { modalVisible, hideModal, showModal } = usePopupModal();

	const { userInfo, profilePic } = useSelector((state) => state.user);
	console.log(profilePic, "profilePic");
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [profileAddress, setProfileAddress] = useState({});
	const navigate = useNavigate();
	const [selectedImage, setSelectedImage] = useState(profilePic);
	const handleCameraClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};
	const onChangeText = (val, key_name) => {
		setProfileAddress((previous) => ({
			...previous,
			[key_name]: val,
		}));
	};
	const handleAddress = async () => {
		const { addr1, addr2 } = profileAddress;
		if (addr1 ? addr1.length > 0 : addr2 ? addr2.length > 0 : false) {
			setLoading(true);
			let token = await localStorage.getItem("key");
			var raw = JSON.stringify({
				addr1: addr1 ? addr1 : userInfo?.addr1,
				addr2: addr2 ? addr2 : userInfo?.addr2,
			});
			try {
				changeaddr(raw, token).then(async (response) => {
					if (response.response === "ok") {
						setLoading(false);
						toast.success("Address change successfully");
					} else {
						setLoading(false);
						toast.error("Something went wrong");
					}
				});
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		} else {
			toast.error("Please change your address.");
		}
	};
	const handleChangeNumber = async (value, key) => {
		if (value.length === 10) {
			if (NumberRegexPattern.test(value)) {
				setLoading(true);
				let token = await localStorage.getItem("key");
				var raw = JSON.stringify({ [key]: value });
				try {
					setmymobilenum(raw, token).then(async (response) => {
						if (response.response === "ok") {
							setLoading(false);
							toast.success(
								`Your mobile number has changed! It'll be activated after 24 hours only.`
							);
						} else {
							setLoading(false);
							toast.error("Something went wrong");
						}
					});
				} catch (error) {
					setLoading(false);
					console.log(error);
				}
			} else {
				toast.error("Please input valid mobile number!");
			}
		}
	};

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		fileInput.current = selectedFile;
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setSelectedImage(reader.result);
				UploadPhoto(reader.result);
			};
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const UploadPhoto = async (file) => {
		setLoading(true);
		const token = localStorage.getItem("key");
		const formData = new FormData();
		formData.append("file", fileInput.current);

		try {
			const response = await uploadmypic(formData, token);
			console.log(response, "upload pic response");
			if (response.response === "ok") {
				toast.success("Profile updated successfully");
				dispatch(setProfilePicture(file));
			} else {
				toast.error("Something went wrong");
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onChangeEmail = (val, key_name) => {
		onChangeText(val, "email");
		let res = EmailRegexPattern.test(val);
		onChangeText(res ? "" : "Email is not valid", "isValidEmail");
		if (res) {
			isemailavailable(JSON.stringify({ email: val })).then((response) => {
				console.log(response);
				onChangeText(
					response.data ? "Email is available" : "Email is not available",
					"isValidEmail"
				);
			});
		}
	};

	const ProfileHeaderSection = () => {
		return (
			<div className="profile-page-sub-section">
				<div className="upper-profile-header">
					<div className="profile-page-picture">
						<div className="profile-page-picture-size">
							<img
								src={selectedImage}
								alt="profile"
								className="profile-page-picture-size"
							/>
						</div>

						<img
							src={camera}
							alt="camera"
							className="camera-inline-with-profile"
							onClick={handleCameraClick}
						/>
						<input
							type="file"
							accept="image/*"
							ref={fileInputRef}
							style={{ display: "none" }}
							onChange={handleFileChange}
						/>
					</div>
					<span className="profile-bio">
						Basic info, like your name and Email ID, that you entered at the
						time of account creation
					</span>
				</div>
				<hr className="profile-hr" />
			</div>
		);
	};

	const ProfileDataComponent = () => {
		return (
			<div className="profile-update-wrapper">
				<Input
					icon={username}
					placeholder={"First Name"}
					value={userInfo?.first_name}
					disable={true}
				/>
				<Input
					icon={username}
					placeholder={"Last Name"}
					value={userInfo?.last_name}
					disable={true}
				/>
				<Input
					icon={phone}
					placeholder={"Mobile Number "}
					value={""}
					defaultValue={userInfo?.mob}
					onChangeText={handleChangeNumber}
					key_name="mobile"
					maxLength={10}
				/>
				<Input
					icon={emailIcon}
					placeholder={"Mail Id "}
					value={userInfo?.email}
					disable={true}
				/>
				<Input
					icon={location}
					placeholder={"Address 1"}
					value={profileAddress?.addr1}
					defaultValue={userInfo?.addr1}
					onChangeText={onChangeText}
					key_name="addr1"
				/>
				<Input
					icon={location}
					placeholder={"Address 2"}
					value={profileAddress?.addr2}
					defaultValue={userInfo?.addr2}
					onChangeText={onChangeText}
					key_name="addr2"
				/>
			</div>
		);
	};
	return (
		<div className="profile-page-container">
			<div className="profile-page-section">
				<span>Personal info</span>
			</div>
			{isLoading && <Loader />}
			{ProfileHeaderSection()}
			{ProfileDataComponent()}

			<div className="profile-button-wrapper">
				<Button name={"SAVE"} onClick={handleAddress} />
			</div>
		</div>
	);
};
