import React from "react";
import "./About.css";
import user from "../../assets/images/user.png";
const About = () => {
	const data = [
		{
			id: 1,
			name: "Ms. Shanthi, Partner",
			about:
				"About 10 Years of experience in a private Limited Company as Director, having knowledge on Quality Assurance",
			exp: "10+ Years",
		},
		{
			id: 2,
			name: "Ms. Rajalakshmi, Partner",
			about:
				"About 10 years of experience in Private Limited Company as Director, having knowledge on Strategy",
			exp: "10+ Years",
		},
		{
			id: 3,
			name: "Srinivasan, Chief Operating Officer",
			about:
				"37+ years of experience in Telecom field, worked for major telecom Operators of India. Having in-depth knowledge on Technical, Finance and Quality. Is an effective business planner and strategist to convert underperforming networks into better performing network. Is sensitive to work on the pulse of the requirement of the customer.",
			exp: "37+ Years",
		},
		{
			id: 4,
			name: "Harikumar, Chief Technical Officer",
			about:
				"37+ years of experience in Telecom field, worked for major National and International Telecom Operators of India. Having in-depth knowledge on Voice, Signaling, Data and Transmission and also very good understanding on various Standards, Process and Quality delivery of service.",
			exp: "37+ Years",
		},
	];
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="about_card">
					<div className="about_container">
						<h1>
							<u style={{ color: "#1577b4" }}>About us</u>
						</h1>
						<p style={{ lineHeight: "22px", letterSpacing: "0.6px" }}>
							Machynworks Software Solutions LLP in partnership with Synerthink
							Solutions LLP is committed to provide technologically advanced
							communication solutions to businesses of all sizes. With a team of
							highly skilled professionals and cutting- edge technology, the
							company has established itself as a leader in the industry. Our
							focus is on service excellence, customer-centric approach, value
							for the customer, partnerships, and continuous innovation through
							learning that has helped to earn a reputation as a trusted
							provider of communication solutions, helping businesses thrive in
							an increasingly connected world. With the management coming from
							diverse background and proven leadership, we continuously invest
							in research and development to stay at the forefront of
							technological advancements in the communication industry. This
							allows us to offer state-of-the-art solutions that enhance
							productivity, efficiency, and provide effective communication to
							our customers.
						</p>
						<h1>
							<u style={{ color: "#1577b4" }}>Management Team</u>
						</h1>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
								justifyContent: "center",
							}}
						>
							{data.map((item, index) => {
								return (
									<div key={index} className="card_management">
										<div className="side front">
											<div className="card_profile">
												<label>Profile</label>
											</div>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													marginTop: 30,
													flexDirection: "column",
													alignItems: "center",
													paddingLeft: 40,
													paddingRight: 40,
												}}
											>
												<img src={user} style={{ width: 100, height: 100 }} />
												<span
													style={{
														marginTop: 20,
													}}
												>
													<label
														style={{
															fontSize: 20,
															textAlign: "center",
														}}
													>
														{item.name}
													</label>
													<p
														style={{
															padding: 0,
															margin: 0,
															fontSize: 14,
															fontWeight: "600",
															textAlign: "center",
															paddingTop: 40,
														}}
													>
														Exp: {item?.exp}
													</p>
												</span>
											</div>
										</div>
										<div className="side back">
											<div className="card_profile">
												<label>About us</label>
											</div>
											<p style={{ fontSize: 14, lineHeight: 1.5 }}>
												{item?.about}
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
