/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar, setProfilePicture } from "../redux/Actions/UserAction";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import menuBurger from "../app/assets/images/burger.png";
import hrlogo from "../app/assets/images/hrlogo.png";
import composesms from "../app/assets/images/composesms.png";
import { useMediaQuery } from "react-responsive";
import { ComposeOTPverify, getmypic, SendSMS } from "../app/API";
import { toast } from "react-hot-toast";
import Button from "./Button";
import Input from "./Input";
import usePopupModal from "./popUpModal";
import localStorage from "redux-persist/es/storage";
import { getUserInfo } from "../utility";
import { clearPersistedState } from "../redux/Store";
function Navbar() {
	const { navbar, userInfo } = useSelector((state) => state.user);
	const { modalVisible, hideModal, showModal } = usePopupModal();
	const location = useLocation();
	const currentRouteName = location.pathname;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [otpValue, setOtpValue] = useState("");
	const [number, setNumber] = useState();
	const [message, setMessage] = useState();

	const [isComposeSMSModal, setComposeSMSModal] = useState(false);

	const handleOtpChange = (event) => {
		setOtpValue(event.target.value);
	};
	const onLogoutHandler = () => {
		const isConfirmed = window.confirm("Are you sure you want to logout?");
		if (isConfirmed) {
			localStorage.removeItem("isLoggedIn");
			localStorage.removeItem("loginTime");
			localStorage.removeItem("key");
			clearPersistedState();
			window.location.reload();
		} else {
			console.log("Logout canceled");
		}
	};
	const submitOTPCompose = async () => {
		let token = await localStorage.getItem("key");
		var raw = JSON.stringify({
			code: otpValue,
		});
		try {
			if (otpValue) {
				ComposeOTPverify(raw, token).then((response) => {
					console.log(response);
					if (response.response === "ok") {
						let time = new Date().getTime();
						console.log(time);
						localStorage.setItem("compose_time", time.toString());
						toast.success("OTP Verify successfully");
						hideModal(false);
						setComposeSMSModal(true);
					} else {
						toast.error("Please enter correct OTP");
					}
				});
			} else {
				toast.error("Please enter OTP");
			}
		} catch (error) {
			console.log(error);
		}
	};
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const isiPadPro = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	useLayoutEffect(() => {
		if (isMobile || isiPadPro) {
			dispatch(setNavbar(false));
		}
	}, [isMobile, isiPadPro]);
	const handleClick = (path) => {
		if (isMobile || isiPadPro) {
			dispatch(setNavbar(false));
		}
		navigate(path);
	};

	useEffect(() => {
		getUserInfo(dispatch);
		getProfiles();
	}, []);

	const getProfiles = async () => {
		const token = await localStorage.getItem("key");
		const result = await getmypic(token);
		if (result?.response === "ok") {
			dispatch(
				setProfilePicture(
					`data:${result.data.content_type};base64,${result.data.profile}`
				)
			);
		}
	};

	const handleComposeSMS = async () => {
		if (isMobile || isiPadPro) {
			dispatch(setNavbar(false));
		}
		navigate("/compose-email");
	};

	return (
		<>
			<div
				onClick={() => dispatch(setNavbar(!navbar))}
				className={`sidebar-overlay  ${navbar ? "open" : "closed"}`}
			/>
			<aside className={`sidebar  ${navbar ? "open" : "closed"}`}>
				<div
					style={{
						display: "flex",
					}}
				>
					<div className={`menubar`}>
						<div style={{ height: 120 }}>
							<img
								className={`burger-button`}
								onClick={() => dispatch(setNavbar(!navbar))}
								src={menuBurger}
								alt="Image description"
							/>
						</div>
					</div>
					<div className={`menubarOpen`}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: 120,
							}}
						>
							<img
								style={{ width: 140 }}
								src={hrlogo}
								alt="Image description"
							/>
							<div
								onClick={() => handleComposeSMS()}
								className="gradient-container"
							>
								<span>
									<i
										className="fa fa-pencil"
										style={{
											color: "#fff",
										}}
									/>{" "}
									Compose Email
								</span>
							</div>
						</div>

						{/* EMAIL section */}
						<div
							onClick={() => handleClick("/")}
							className={`drawer-item ${
								currentRouteName === "/" ? "selected" : ""
							}`}
						>
							<p>Certifi Email Sent</p>
						</div>
						<div
							onClick={() => handleClick("/failed-email")}
							className={`drawer-item ${
								currentRouteName === "/failed-email" ? "selected" : ""
							}`}
						>
							<p>Email Not Delivered</p>
						</div>
						<div
							onClick={() => handleClick("/draft-email")}
							className={`drawer-item ${
								currentRouteName === "/draft-email" ? "selected" : ""
							}`}
						>
							<p>Draft Emails</p>
						</div>
						<div
							onClick={() => handleClick("/payment-history")}
							className={`drawer-item ${
								currentRouteName === "/payment-history" ? "selected" : ""
							}`}
						>
							<p>Payment History</p>
						</div>
						<div
							onClick={() => handleClick("/reverify-certificate")}
							className={`drawer-item ${
								currentRouteName === "/reverify-certificate" ? "selected" : ""
							}`}
						>
							<p>ReVerify Certificate</p>
						</div>
						<div
							onClick={() => handleClick("/profile")}
							className={`drawer-item ${
								currentRouteName === "/profile" ? "selected" : ""
							}`}
						>
							<p>Profile</p>
						</div>
						<div
							onClick={() => handleClick("/setting")}
							className={`drawer-item ${
								currentRouteName === "/setting" ? "selected" : ""
							}`}
						>
							<p>Settings</p>
						</div>
						<div onClick={onLogoutHandler} className={`drawer-item logout`}>
							<i
								className="fa fa-sign-out fa-lg"
								style={{
									color: "#000",
									display: "flex",
									justifyContent: "flex-end",
									paddingRight: 20,
									cursor: "pointer",
								}}
							/>
							<p style={{ color: "#000" }}>Logout</p>
						</div>
					</div>
				</div>
			</aside>

			{/* Compose SMS OTP MODAL START */}

			{modalVisible && (
				<div className="modal-wrapper" style={{ zIndex: 9999999 }}>
					<div
						style={{
							backgroundColor: "#fff",
							width: 540,
							paddingBottom: 60,
							paddingTop: 20,
							borderRadius: 12,
						}}
					>
						<i
							onClick={() => hideModal(false)}
							className="fa fa-times-circle fa-lg"
							style={{
								color: "#e98936",
								display: "flex",
								justifyContent: "flex-end",
								paddingRight: 20,
								cursor: "pointer",
							}}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<h2
								className="gradient-text"
								style={{
									color: "#e98936",
									marginBottom: 30,
									textTransform: "uppercase",
								}}
							>
								OTP To Compose SMS
							</h2>
							<div
								className="input-wrapper"
								style={{ width: isMobile ? 240 : null }}
							>
								<input
									className="input"
									placeholder="Enter OTP"
									value={otpValue}
									onChange={handleOtpChange}
								/>
							</div>
							<span
								style={{
									marginRight: 20,
									fontSize: 12,
									fontWeight: "400",
									textAlign: "center",
									paddingLeft: 40,
									paddingRight: 40,
									marginTop: 10,
								}}
							>
								An OTP is sent to registered email id. OTP is valid for 04:55
								mins.
							</span>
							<div style={{ marginTop: 20 }}>
								<Button name={"SUBMIT"} onClick={submitOTPCompose} />
							</div>
						</div>
					</div>
				</div>
			)}
			{/*====== END ====== */}
		</>
	);
}

export default Navbar;
