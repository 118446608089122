import React, { useEffect, useMemo, useState } from "react";
import "./TableContainer.css";
import { useMediaQuery } from "react-responsive";
import {
	copyExcelData,
	excelDownload,
	formatDate,
	printExcelFile,
} from "../utility";
import download from "../app/assets/images/download.png";
import ExportExcel from "../app/assets/images/ExportExcel.png";
import copy from "../app/assets/images/copy.png";
import Print from "../app/assets/images/Print.png";

import { useSelector } from "react-redux";

const Table = ({ data, onClick, loading, type, lable, isSuccess, isDraft }) => {
	const tableData = data || [];
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const itemsPerPage = 30;
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1); // Reset to the first page when the table data changes
	}, [tableData]);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
	const total_Pages = Math.ceil(tableData.length / itemsPerPage);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const nextPage = () => {
		if (total_Pages > currentPage) {
			paginate(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage > 1) {
			paginate(currentPage - 1);
		}
	};
	const PaginationComponent = () => {
		return (
			<div className="pagination-wrapper">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						margin: "6px",
					}}
				>
					<span style={{ marginRight: "10px", color: "#fff" }}>
						Page {currentPage} of {total_Pages}
					</span>
					<span style={{ marginRight: "10px", color: "#fff" }}>
						Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
						{tableData.length} entries
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<i
						className="fa fa-arrow-circle-left fa-lg"
						onClick={() => prevPage()}
						style={{
							color: "#e98936",
							marginRight: 10,
							cursor: "pointer",
							backgroundColor: "#fff",
							padding: 6,
							borderRadius: 100,
						}}
					/>
					<i
						className="fa fa-arrow-circle-right fa-lg"
						onClick={() => nextPage()}
						style={{
							color: "#e98936",
							marginRight: 10,
							cursor: "pointer",
							backgroundColor: "#fff",
							padding: 6,
							borderRadius: 100,
						}}
					/>
				</div>
			</div>
		);
	};

	const downloadOption = () => {
		return (
			<>
				<div className="download-image-absolute">
					<img
						onClick={() => excelDownload(currentItems, lable)}
						src={ExportExcel}
						alt="download"
						className="download-image"
						title="Download"
					/>
				</div>
				<div className="download-image-absolute1">
					<img
						onClick={() => printExcelFile(currentItems, lable)}
						src={Print}
						alt="download"
						className="download-image"
						title="Print"
					/>
				</div>
				<div className="download-image-absolute2">
					<img
						onClick={() => copyExcelData(currentItems)}
						src={copy}
						alt="download"
						className="download-image"
						style={{ width: 20 }}
						title="Copy"
					/>
				</div>
			</>
		);
	};
	if (!isMobile) {
		return (
			<table className="table-wrapper" style={{ marginBottom: 30 }}>
				<tr className="row-wrapper">
					<th>Recipient</th>
					<th>Subject</th>
					{!isDraft && isSuccess && <th>SMS Delivery</th>}
					{!isDraft && <th>Email Delivery</th>}
					{!isDraft && isSuccess && <th>Email Read Time</th>}
					{isSuccess && <th>Status</th>}
					{!isDraft ? (
						<th style={{ textAlign: "center" }}>View Certificate</th>
					) : null}
					{isDraft && <th>Action</th>}
				</tr>
				{currentItems?.map((item, index) => {
					return (
						<tr
							key={index}
							className="table-list"
							onClick={() => onClick({ id: item?.id })}
						>
							<td>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<span>{item?.recipient}</span>
								</div>
							</td>
							<td>
								{item?.subject?.length > 40
									? `${item?.subject?.slice(0, 40)}...`
									: item?.subject}
							</td>
							{!isDraft && isSuccess && (
								<td>{formatDate(item?.sms_sent_time)}</td>
							)}
							{!isDraft && <td>{formatDate(item?.time)}</td>}
							{!isDraft && isSuccess && <td>{formatDate(item?.read_time)}</td>}
							{isSuccess && (
								<td
									style={{
										color: "#14619d",
									}}
								>
									{item?.read_status == 0 ? "✔" : "✔✔"}
								</td>
							)}
							{!isDraft ? (
								<td style={{ textAlign: "center" }}>
									<img
										src={copy}
										alt="view"
										style={{
											width: 20,
											cursor: "pointer",
											alignSelf: "center",
										}}
										onClick={() =>
											onClick({
												type: type,
												id: item.id,
											})
										}
									/>
								</td>
							) : null}
							{isDraft && (
								<td
									onClick={()=>onClick({ id: item?.id, flag:'draft' })}
									style={{
										color: "#14619d",
										cursor: "pointer",
									}}
								>
									<i className="fa fa-pencil" />
								</td>
							)}
						</tr>
					);
				})}
				{tableData.length > 30 && PaginationComponent()}
				{downloadOption()}
				{tableData.length === 0 && !loading && (
					<h3
						style={{ position: "absolute", top: "50%", bottom: 0, left: "45%" }}
					>
						No Data Found!!
					</h3>
				)}
			</table>
		);
	} else {
		return (
			<div className="table-wrapper">
				{currentItems.map((item) => (
					<div
						key={item.id} // Don't forget to add a unique key for each item when mapping over an array
						className={"table-list-mobile"}
						onClick={() => onClick({ id: item?.id })}
					>
						<div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div className="table-mobile-username-date">
									<span style={{ fontWeight: "bold" }}>{item?.recipient}</span>
									{isSuccess && (
										<span
											style={{
												color: "#14619d",
											}}
										>
											{item?.read_status == 0 ? "✔" : "✔✔"}
										</span>
									)}
								</div>
							</div>
							<div className="table-mobile-subject">
								<span>
									<b>Sub: </b>
									{item?.subject?.length > 120
										? `${item?.subject?.slice(0, 120)}...`
										: item?.subject}
								</span>
								<br />
								{!isDraft && isSuccess && (
									<span
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 20,
										}}
									>
										<b>SMS Delivery</b>
										{formatDate(item?.sms_sent_time)}
									</span>
								)}

								{!isDraft && (
									<>
										<br />
										<span
											style={{
												display: "flex",
												justifyContent: "space-between",
												backgroundColor: "#14619C20",
												padding: 10,
												borderRadius: 4,
											}}
										>
											<b>Email Delivery</b>
											{formatDate(item?.time)}
										</span>
									</>
								)}

								{!isDraft && isSuccess && (
									<>
										<br />
										<span
											style={{
												display: "flex",
												justifyContent: "space-between",
												backgroundColor: "#14619C20",
												padding: 10,
												borderRadius: 4,
											}}
										>
											<b>Email Read Time</b>
											{formatDate(item?.read_time)}
										</span>
									</>
								)}
								{!isDraft ? (
									<span
										onClick={() =>
											onClick({
												type: type,
												id: item.id,
											})
										}
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 12,
										}}
									>
										<b>View Certificate</b>
										<img
											src={copy}
											alt="view"
											style={{
												width: 14,
												cursor: "pointer",
												alignSelf: "center",
											}}
										/>
									</span>
								) : null}
								{isDraft && (
									<span
									onClick={() =>
										onClick({
											id: item.id,
											flag:"draft"
										})
									}
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 12,
											color: "#14619d",
										}}
									>
										<b>Action</b>
										<i className="fa fa-pencil" />
									</span>
								)}
							</div>
						</div>
					</div>
				))}
				{tableData.length > 30 && (
					<div
						style={{
							position: "absolute",
							bottom: 80,
							right: 0,
							width: "90%",
							fontSize: 12,
						}}
					>
						{PaginationComponent()}
					</div>
				)}

				{tableData.length === 0 && !loading && (
					<h3
						style={{ position: "absolute", top: "40%", bottom: 0, left: "35%" }}
					>
						No Data Found!!
					</h3>
				)}
			</div>
		);
	}
};

export default Table;
