/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { GetDraftMail } from "../../API";
import searchIcon from "../../assets/images/search.png";
import refresh from "../../assets/images/refresh.png";
import Loader from "../../../components/Loader";
import usePopupModal from "../../../components/popUpModal";
import { toast } from "react-hot-toast";
import { getCertificate } from "../../../utility";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const DraftMail = () => {
	const { modalVisible, hideModal, showModal } = usePopupModal();
	const dispatch = useDispatch();
	useEffect(() => {
		getToken();
	}, []);
	const navigate = useNavigate();
	const [inbox, setInbox] = useState();
	const [isLoading, setLoading] = useState(false);
	const [today, setToday] = useState();
	const [search, setSearch] = useState();
	const [filteredDataSource, setFilteredDataSource] = useState();
	const onClick = ({ type, id, flag }) => {
		if (flag == "draft") {
			navigate("/compose-email", {
				state: {
					id: id,
				},
			});
		} else {
			getCertificate(type, id);
		}
	};

	const onSearch = (text) => {
		if (text) {
			const newData = inbox.filter(function (item) {
				const recipient = item.recipient.toUpperCase();
				const body = item.body.toUpperCase();
				const itemData = item.subject.toUpperCase();
				const textData = text.toUpperCase();
				return (
					itemData.indexOf(textData) > -1 ||
					recipient.indexOf(textData) > -1 ||
					body.indexOf(textData) > -1
				);
			});
			setFilteredDataSource(newData);
			setSearch(text);
		} else {
			setFilteredDataSource();
			setSearch(text);
		}
	};
	const getToken = async () => {
		let token = await localStorage.getItem("key");
		setLoading(true);
		try {
			GetDraftMail(token).then((response) => {
				if (response?.data) {
					setInbox(response?.data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error, "error");
			setLoading(false);
		}
	};
	return (
		<div className="table-container">
			<div className="refresh">
				<div
					onClick={() => getToken()}
					style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
				>
					<img
						src={refresh}
						alt="refresh"
						style={{ width: 19.5, height: 20, paddingRight: 20 }}
					/>
					<span>Refresh</span>
				</div>
				<div className="input-wrapper">
					<input
						className="input"
						placeholder="Search"
						onChange={(e) => onSearch(e.target.value)}
					/>
					<img
						src={searchIcon}
						alt="search"
						style={{ width: 18, height: 18 }}
					/>
				</div>
			</div>
			{isLoading && <Loader />}
			<Table
				data={search ? filteredDataSource : inbox}
				isSuccess={false}
				loading={isLoading}
				onClick={onClick}
				type={"email"}
				isDraft={true}
				lable={"Draft Email data"}
			/>
		</div>
	);
};

export default DraftMail;
