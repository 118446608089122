import React, { useEffect, useState } from "react";
import "./login.css";
import { useDispatch } from "react-redux";
import Input from "../../../components/Input";
import password from "../../assets/images/password.png";
import { EmailVerified, Reset, ResetNewPassword } from "../../API";
import { toast } from "react-hot-toast";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [loginCredential, setLoginCredential] = useState({});
	const [ispassword, setPassword] = useState();
	const [cpassword, setCPassword] = useState();

	const onChangeText = (val, key_name) => {
		if (key_name === "password" || key_name === "cpassword") {
			if (key_name === "password") {
				setPassword(val);
			} else {
				setCPassword(val);
			}
			var password = CryptoJS.SHA1(val);
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: password,
			}));
		} else {
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: val,
			}));
		}
	};
	const [isLoading, setLoading] = useState(false);
	const [userId, setUserId] = useState();
	const [inputKey, setInputKey] = useState(0);

	useEffect(() => {
		const url = window.location.href;
		const pathSegments = url.split("/");
		const id = pathSegments[4];
		const verify_code = pathSegments[5];
		console.log("id:", id);
		console.log("verify_code:", verify_code);
		VerifiedEmail(id, verify_code);
	}, []);

	const VerifiedEmail = (id, verify_code) => {
		setLoading(true);
		var formdata = new FormData();
		formdata.append("id", id);
		formdata.append("verify_code", verify_code);
		try {
			EmailVerified(formdata).then((response) => {
				console.log(JSON.stringify(response, null, 4));
				if (response?.response === "ok") {
					console.log(response);
					setUserId(response?.data?.user_id);
					setLoading(false);
				} else if (response?.response?.includes("Duplicate entry")) {
					setLoading(false);
				} else {
					setLoading(false);
					toast.error("Verification failed!");
				}
			});
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Something went wrong!");
		}
	};

	const validatePassword = () => {
		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;

		const uppercasePassword = uppercaseRegExp.test(ispassword);
		const lowercasePassword = lowercaseRegExp.test(ispassword);
		const digitsPassword = digitsRegExp.test(ispassword);
		const specialCharPassword = specialCharRegExp.test(ispassword);
		const minLengthPassword = minLengthRegExp.test(ispassword);
		if (
			uppercasePassword &&
			lowercasePassword &&
			digitsPassword &&
			specialCharPassword &&
			minLengthPassword
		) {
			handleSubmit();
		} else if (ispassword === cpassword) {
			toast.error("please enter valid password");
		} else {
			toast.error(`Password doesn't match`);
		}
	};

	const handleSubmit = () => {
		if (loginCredential?.password && loginCredential?.cpassword) {
			if (ispassword !== cpassword) {
				toast.error(`Password doesn't match`);
				return;
			}
			var formdata = new FormData();
			formdata.append("user_id", userId);
			formdata.append("password", loginCredential.password);
			try {
				ResetNewPassword(formdata).then((response) => {
					if (response?.response === "ok") {
						console.log(response);
						toast.success("Password reset successfully!");
						setLoading(false);
						navigate("/");
					} else {
						setInputKey((prevKey) => prevKey + 1);
					}
				});
			} catch (error) {
				console.log(error);
				setLoading(false);
				toast.error("Something went wrong!");
			}
		} else {
			toast.error("Both field are required!");
		}
	};

	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					textAlign: "center",
					height: "100vh",
				}}
			>
				<div className="card">
					<img
						alt="img"
						src={require("../../assets/images/logo.png")}
						className="logo"
					/>
					<p className="welcome">Reset Password</p>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							margin: "auto",
						}}
					>
						<Input
							icon={password}
							placeholder={"Enter new password"}
							onChangeText={onChangeText}
							key_name="password"
							value={ispassword}
							type="password"
							key={inputKey}
						/>
						<span style={{fontSize:12, textAlign:'left', width:'75%', margin:'auto', marginBottom:20, color:'gray'}}>
							1 upper case character, 1 lower case character, 1 digit and 1
							special character other than underscore, It must contain at least
							8 characters and no more than 32 characters.
						</span>
						<Input
							icon={password}
							placeholder={"Confirm password"}
							onChangeText={onChangeText}
							key_name="cpassword"
							value={cpassword}
							type="password"
							key={inputKey + 1}
						/>
					</div>

					<div class="container" onClick={() => validatePassword()}>
						<a class="btn btn-1">Submit</a>
					</div>
					<p style={{ textAlign: "center",color:'rgba(0,0,0,0.4)' }}><a style={{textDecoration:'none',}} href="https://play.google.com/store/apps/details?id=com.certify">Download Our App</a></p>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
