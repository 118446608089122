export default {
	Login: "Login",
	ForgotPassword: "Forgot Password?",
	EnterPassword: "Enter Password",
	EnterEmail: "Enter Email",
	DontHaveAccount: `Don't have an account?`,
	Create: "Register",
	FirstName: "Enter First Name",
	LastName: "Enter Last Name",
	PassDesc: `1 upper case character, 1 lower case character, 1 digit and 1 special character other than underscore, It must contain at least 8 characters and no more than 32 characters.`,
	ConfirmPassword: "Enter Confirm Password",
	ContactNumber: "Enter Contact Number",
	SecretQuestion: "Secret Question",
	EnterAnswer: "Enter Answer",
	Address: "Address",
	ByClick: "By clicking Sign Up, you agree to our",
	term: "term",
	AndRead: "and that you have read our",
	DataPolicy: "Data Policy and Cookie Use",
	CreateAccount: "Register",
	Agreement: "Agreement",
	Accept: "Accept",
	CertifiEmailSent: "Certifi Email Sent",
	EmailNotDelivered: "Email Not Delivered",
	DataNotFound: "No Data Found!",
	Subject: "Subject",
	SMSDelivery: "SMS Delivery",
	EmailReadTime: "Email Read Time",
	Home: "Home",
	Payment: "Payment",
	Profile: "Profile",
	Setting: "Setting",
	Settings: "Settings",
	Email: "Email",
	SrNo: "Sr. No.",
	CertifiEmailCompose: "Certifi Email Compose",
	EnterDescription: "Enter Description",
	Send: "Send",
	AttachFile: "Attach a file",
	Save: "Save",
	BasicInfo:
		"Basic info, like your name and Email ID, that you entered at the time of account creation",
	Cancel: "Cancel",
	DraftEmail: "Draft Email",
	AggrementSignature:'Aggrement Signature'
};
