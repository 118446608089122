import React, { useEffect, useState } from "react";
import Login from "./app/screens/authScreens/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Registration from "./app/screens/authScreens/Registration";
import { useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import "./App.css";
import Header from "./components/Header";
import { Profile } from "./app/screens/mainScreens/Profile";
import ReVerifyCertificate from "./app/screens/mainScreens/ReVerifyCertificate";
import Setting from "./app/screens/mainScreens/Setting";
import PaymentHistory from "./app/screens/mainScreens/PaymentHistory";
import TeriffPlans from "./app/screens/mainScreens/TeriffPlans";
import Emails from "./app/screens/mainScreens/SuccessEmails";
import { Toaster } from "react-hot-toast";
import Forgot from "./app/screens/authScreens/Forgot";
import BottomTabNavigation from "./components/BottomTabNavigation";
import { useMediaQuery } from "react-responsive";
import AuthNavbar from "./app/screens/authScreens/AuthNavbar";
import About from "./app/screens/authScreens/About";
import Contact from "./app/screens/authScreens/Contact";
import Product from "./app/screens/authScreens/Product";
import { clearPersistedState } from "./redux/Store";
import VerifiedEmail from "./app/screens/authScreens/VerifiedEmail";
import ResetPassword from "./app/screens/authScreens/ResetPassword";
import RegistrationTarrifPlan from "./app/screens/authScreens/RegistrationTarrifPlan";
import Aggrement from "./app/screens/authScreens/Agreement";
import FailedEmails from "./app/screens/mainScreens/FaiiledEmails";
import ComposeEmail from "./app/screens/mainScreens/ComposeMail";
import DraftMail from "./app/screens/mainScreens/DraftMail";
import CertifiEmail from "./app/screens/authScreens/CertifiEmail";
import CertifiDetails from "./app/screens/authScreens/CertifiDetails";
const Main = () => {
	const [token, setToken] = useState(null);
	const tokenfunc = useSelector((state) => state.user.tokenfunc);
	const { navbar, userInfo } = useSelector((state) => state.user);
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	useEffect(() => {
		getToken();
	}, []);

	const handleLogout = () => {
		localStorage.removeItem("isLoggedIn");
		localStorage.removeItem("loginTime");
		localStorage.removeItem("key");
		clearPersistedState();
		window.location.reload();
	};
	useEffect(() => {
		const checkSession = () => {
			const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
			const storedLoginTime = localStorage.getItem("loginTime");
			if (storedIsLoggedIn === "true" && storedLoginTime) {
				const currentTime = new Date().getTime();
				const elapsedTime = currentTime - parseInt(storedLoginTime, 10);

				if (elapsedTime >= 15 * 60 * 1000) {
					handleLogout();
				}
			}
		};
		const intervalId = setInterval(checkSession, 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const getToken = () => {
		let res = localStorage.getItem("key");
		setToken(res);
	};

	return (
		<>
			<Toaster
				position="top-center"
				containerStyle={{ position: "absolute", top: 10, zIndex: 99999 }}
			/>
			{tokenfunc || token ? (
				<div>
					{!isMobile && <Header />}
					<Navbar />
					<div className={`main ${navbar ? "open" : "closed"}`}>
						<Routes>
							<Route path="/" element={<Emails />} />
							<Route path="/draft-email" element={<DraftMail />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/setting" element={<Setting />} />
							<Route path="/payment-history" element={<PaymentHistory />} />
							<Route path="/teriff-plans" element={<TeriffPlans />} />
							<Route path="/failed-email" element={<FailedEmails />} />
							<Route path="/compose-email" element={<ComposeEmail />} />
							<Route path="/compose-email" element={<ComposeEmail />} />

							<Route
								path="/reverify-certificate"
								element={<ReVerifyCertificate />}
							/>
							<Route path="*" element={<Navigate to="/" />} />
						</Routes>
						{isMobile && (
							<div
								style={{
									position: "absolute",
									bottom: 0,
									height: "auto",
									margin: "auto",
									width: "100%",
									backgroundColor: "red",
								}}
							>
								<BottomTabNavigation />
							</div>
						)}
					</div>
				</div>
			) : (
				<>
					<AuthNavbar />
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/registration" element={<Registration />} />
						<Route path="/agreement" element={<Aggrement />} />
						<Route path="/certifi-email" element={<CertifiEmail />} />
						<Route path="/certifi-email-details" element={<CertifiDetails />} />


						<Route
							path="/registerplans/:orderId?/:email?"
							element={<RegistrationTarrifPlan />}
						/>

						<Route path="/forgot" element={<Forgot />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						{/* <Route path="/verify" element={<Verify />} /> */}
						<Route path="/product" element={<Product />} />
						<Route
							path="/verified-email/:id?/:verify_code?"
							element={<VerifiedEmail />}
						/>
						{/* <Route path="/email/whitelist/:id?/:verify_code?" element={<WhiteListEmailVerify />} /> */}
						<Route
							path="/reset-password/:id?/:verify_code?"
							element={<ResetPassword />}
						/>

						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</>
			)}
		</>
	);
};

export default Main;
