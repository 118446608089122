import React from "react";
import Main from "./Main";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
const App = () => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<Main />
			</PersistGate>
		</Provider>
	);
};

export default App;
