import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	setLanguage,
	setTokenFunction,
} from "../../../redux/Actions/UserAction";
import Input from "../../../components/Input";
import username from "../../assets/images/username.png";
import password from "../../assets/images/password.png";
import CryptoJS from "crypto-js";
import { login } from "../../API";
import { toast } from "react-hot-toast";
const Login = () => {
	const { locale } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loginCredential, setLoginCredential] = useState({});
	const [ispassword, setPassword] = useState();
	const [language, setLanguages] = useState("en"); // State to manage selected language
	const handleLanguageChange = (language) => {
		if (language == "hi") {
			dispatch(setLanguage("Hindi"));
		} else {
			dispatch(setLanguage("English"));
		}
		setLanguages(language);
		console.log(`Selected language: ${language}`);
	};
	const onChangeText = (val, key_name) => {
		if (key_name === "password") {
			setPassword(val);
			var password = CryptoJS.SHA1(val);
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: password,
			}));
		} else {
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: val,
			}));
		}
	};
	const handleLogin = async () => {
		if ((loginCredential?.email, loginCredential?.password)) {
			let loader = toast.loading("Loading...");
			var formdata = new FormData();
			formdata.append("username", loginCredential?.email);
			formdata.append("password", loginCredential?.password);
			try {
				login(formdata).then(async (response) => {
					if (response?.token) {
						toast.success("Login successfully!");
						toast.remove(loader);
						localStorage.setItem("key", response?.token);
						dispatch(setTokenFunction(true));
						const loginTime = new Date().getTime();
						localStorage.setItem("isLoggedIn", "true");
						localStorage.setItem("loginTime", loginTime);
					} else {
						toast.remove(loader);
						toast.error("Please enter correct credential!");
					}
				});
			} catch (error) {
				toast.remove(loader);
				toast.error("Something went wrong!", {
					duration: 2000,
				});
				console.log("error", error);
			}
		} else {
			toast.error("Both field are mendatory!", {
				duration: 2000,
			});
		}
	};

	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					textAlign: "center",
					height: "100vh",
				}}
			>
				<div className="card">
					<img
						alt="img"
						src={require("../../assets/images/logo.png")}
						className="logo"
					/>
					<p className="welcome">WELCOME</p>
					<select
						onChange={(e) => handleLanguageChange(e.target.value)} // Function to handle language change
						className="language-selector"
						style={{ padding: "5px", borderRadius: "4px", marginBottom: 10 }}
						value={language}
					>
						<option value="en">🌐 English</option>
						<option value="hi">🌐 Hindi</option>
					</select>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							margin: "auto",
						}}
					>
						<Input
							icon={username}
							placeholder={locale?.EnterEmail}
							onChangeText={onChangeText}
							key_name="email"
							value={loginCredential?.email}
						/>

						<Input
							icon={password}
							placeholder={locale?.EnterPassword}
							onChangeText={onChangeText}
							key_name="password"
							value={ispassword}
							type={"password"}
						/>
					</div>
					<p onClick={() => navigate("/forgot")} className="forgot-password">
						{locale?.ForgotPassword}
					</p>
					<div class="container">
						<a class="btn btn-1" onClick={handleLogin}>
							{locale?.Login}
						</a>
						<a class="btn btn-2">
							<Link
								to={"/registration"}
								style={{ textDecoration: "none", color: "#fff" }}
							>
								{locale?.Create}
							</Link>
						</a>
					</div>
					<p style={{ textAlign: "center", color: "rgba(0,0,0,0.4)" }}>
						<a
							style={{ textDecoration: "none" }}
							href="https://play.google.com/store/apps/details?id=com.certify"
						>
							Download Our App
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
